<template>
	<v-app>
		<v-main>
			<v-container>
				<v-row justify="center" class="pa-6">
					<v-col>
						<h1 class="text-center font-weight-light">{{ strings.welcome }}</h1>
					</v-col>
				</v-row>
				<v-row align="center" justify="center" class="mb-6">
					<v-btn-toggle v-model="lang" mandatory tile group>
						<v-btn value="cz">CZ</v-btn>
						<v-btn value="en">EN</v-btn>
					</v-btn-toggle>
				</v-row>
				<v-row align="center" justify="center">
					<v-col cols="10">
						<p class="text-center">{{ strings.what_is_this }} <span class="yn-serial">{{ serial }}</span>.
						<p class="text-center">{{ strings.whats_next }}</p>
					</v-col>
				</v-row>
				<v-row align="center" justify="center">
					<v-col cols="12" md="8">
						<v-card :loading="loading" elevation="24" class="mx-6">
							<v-card-text>
								<v-row>
									<v-col>
										<v-form v-model="form_valid" ref="form">
											<v-text-field
												v-model="name"
												:label="strings.name"
												required
												:rules="[vrfy_length(2, strings.name_err)]"
												validate-on-blur
												@keydown.enter="$refs.mail.focus()"
												prepend-icon="mdi-card-account-details-outline"
											/>
											<v-text-field
												v-model="mail"
												ref="mail"
												:label="strings.mail"
												required
												:rules="[vrfy_email(strings.mail_err_empty, strings.mail_err)]"
												validate-on-blur
												prepend-icon="mdi-email-outline"
												@keydown.enter="$refs.phone.focus()"
											/>
											<v-text-field
												type="number"
												v-model="phone"
												ref="phone"
												:label="strings.phone"
												prepend-icon="mdi-cellphone-iphone"
												@keydown.enter="$refs.rel.focus()"
											/>
											<v-select
												ref="rel"
												v-model="relationship"
												:items="strings.rel_items"
												:label="strings.rel"
												prepend-icon="mdi-home-city-outline"
											/>
											<v-checkbox v-model="agreed">
												<template v-slot:label>
													<div>{{ strings.i_have_read }} <a target="_blank" @click.stop :href="strings.tos_url">{{ strings.tos }}</a>.</div>
												</template>
											</v-checkbox>
										</v-form>
									</v-col>
								</v-row>
							</v-card-text>
							
							<v-card-actions>
								<v-btn block text color="primary" @click="register" :disabled="button_disabled">
									{{ strings.go_button }}
								</v-btn>
							</v-card-actions>
						</v-card>
					</v-col>
				</v-row>
			</v-container>
			<v-overlay :value="curtain" color="#fff" opacity="1.0" :dark="false">
				<v-alert v-if="alert.type" prominent :type="alert.type" elevation="12" class="mx-6">
					<v-row align="center" justify="end">
						<v-col class="grow">
							{{ alert.text }}
						</v-col>
						<v-col class="shrink" v-if="alert.mailto">
							<v-btn :href="alert.mailto">{{ strings.contact_support }}</v-btn>
						</v-col>
					</v-row>
				</v-alert>
			</v-overlay>
		</v-main>
	</v-app>
</template>

<script>
import axios from "axios";

let API = axios.create({
	baseURL: "https://mynew.wafe.eu/api/1"
});

const i18n = {
	cz: {
		welcome: "Vítejte",
		what_is_this: "Začínáte registraci nového účtu k ovládání Vaší rekuperační větrací jednotky WAFE. Můžete-li, zkontrolujte, že seriové číslo na jednotce je",
		whats_next: "Zadejte své údaje do formuláře. Vaše nové heslo Vám bude zasláno na e-mail spolu s instrukcemi, jak pokračovat.",
		name: "Jméno a příjmení",
		name_err: "Prosím vyplňte jméno.",
		mail: "E-mail",
		mail_err_empty: "E-mail slouží jako přihlašovací jméno, proto ho prosím vyplňte.",
		mail_err: "Tohle nevypadá jako skutečná e-mailová adresa.",
		phone: "Telefonní číslo",
		rel: "Vztah k objektu",
		rel_items: [
			{text: "Vlastník", value: "own"},
			{text: "Krátkodobý nájem (do 1 roku)", value: "rent-short"},
			{text: "Dlouhodobý nájem (déle než 1 rok)", value: "rent-long"},
			{text: "Jiný", value: "other"}
		],
		i_have_read: "Souhlasím s",
		tos_url: "/docs/tos-cs.pdf",
		tos: "obchodními podmínkami",
		go_button: "Pokračovat",
		mail_subject: "Probl%C3%A9m%20s%20registrac%C3%AD%20nov%C3%A9%20jednotky",
		mail_template: "Dobrý den,\n\nnepodařilo se mi zaregistrovat svoji novou jednotku na URL kvůli chybě aplikace.\n\nName: NAME\nPhone: PHONE\nMail: MAIL\nRel: REL\n\nProsím o rychlou nápravu.",
		contact_support: "Kontaktovat podporu",
		alert_good: "Registrace provedena. Odeslali jsme Vám e-mail s novým heslem a odkazem k přihlášení.",
		alert_fail: {
			early: "Registraci nelze zahájit.",
			late: "Registrační proces se nepodařilo dokončit."
		}
	},
	en: {
		welcome: "Welcome",
		what_is_this: 'You\'re about to setup a new account for your WAFE heat recovery ventilation unit. If you can, verify the serial number on the device is',
		whats_next: "Enter your information below. Your new password will be sent to your e-mail address along with instructions on how to continue.",
		name: "First and last name",
		name_err: "Please enter your name.",
		mail: "E-mail",
		mail_err_empty: "Required: your e-mail will become your login name.",
		mail_err: "This does not look like a valid e-mail address.",
		phone: "Phone number",
		rel: "Relationship to the property",
		rel_items: [
			{text: "Owner", value: "own"},
			{text: "Short-term rent (up to 1 year)", value: "rent-short"},
			{text: "Long-term rent (longer than 1 year)", value: "rent-long"},
			{text: "Other", value: "other"}
		],
		i_have_read: "I have read and agree to the ",
		tos_url: "/docs/tos-en.pdf",
		tos: "Terms of Service",
		go_button: "Continue",
		mail_subject: "New%20device%20registration%20issue",
		mail_template: "Hi, I was unable to complete the registration process on URL due to an application error.\n\nName: NAME\nPhone: PHONE\nMail: MAIL\nRel: REL\n\nPlease contact me ASAP.",
		contact_support: "Contact support",
		alert_good: "Registration completed. Check your inbox for further instructions.",
		alert_fail: {
			early: "The registration process failed to start.",
			late: "Unable to complete the registration process."
		}
	}
};


const mail_regexp = new RegExp("^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+[.][a-zA-Z0-9.-]{2,}$");

export default {
	name: "App",
	components: {},
	data: function () {
		return {
			token: null,
			loading: false,
			lang: "cz",
			strings: i18n.cz,
			serial: "",
			enrollment_enabled: true,
			name: "",
			mail: "",
			phone: "",
			agreed: false,
			form_valid: false,
			relationship: null,
			alert: {
				type: null,
				text: null,
				mailto: null
			},
			curtain: true
		}
	},
	methods: {
		api_call_get_object: function (method, on_success) {
			let inst = this;
			
			API.get(method)
				.then(response => {
					console.log(`GET ${method} -> `, response.data);
					
					if (response.data.success != true) {
						inst.alert_fail("early");
					} else {
						on_success(response.data);
					}
				})
				.catch(function () {
					inst.alert_fail("early");
				});
		},
		api_call_post_object: function (method, obj, on_success) {
			let inst = this;
			
			API.post(method, obj)
				.then(response => {
					console.log(`POST ${method}:`, obj, "->", response);
					
					if (response.data.success != true) {
						inst.alert_fail("late");
					} else {
						on_success(response.data);
					}
				})
				.catch(error => {
					inst.alert_fail("late");
				});
		},
		
		register: function () {
			let inst = this;
			this.loading = true;
			let enrollment_request = {
				name: this.name,
				mail: this.mail,
				phone: this.phone,
				rel: this.relationship,
				en: this.lang == "en"
			};
			
			this.api_call_post_object(`/yn/${this.token}`, enrollment_request, function (resp) {
				inst.loading = false;
				inst.alert_good();
			});
		},
		vrfy_length: function (length, message) {
			return function (text) {
				if (text.length < length) {
					return message;
				} else {
					return true;
				}
			}
		},
		vrfy_email: function (message_on_empty, message_on_invalid) {
			return function (text) {
				if (text.length == 0) {
					return message_on_empty;
				}
				
				return mail_regexp.test(text) || message_on_invalid;
			}
		},
		alert_fail: function (phase) {
			let text = this.strings.mail_template;
			
			text = text.replaceAll("URL", window.location.href);
			text = text.replaceAll("NAME", this.name);
			text = text.replaceAll("MAIL", this.mail);
			text = text.replaceAll("PHONE", this.phone);
			text = text.replaceAll("REL", this.relationship);
			
			this.alert.type = "error";
			this.alert.text = this.strings.alert_fail[phase];
			this.alert.mailto = "mailto:support@wafe.eu?subject=" + this.strings.mail_subject + "&body=" + encodeURIComponent(text);
			
			this.loading = false;
			this.curtain = true;
		},
		alert_good: function () {
			this.alert.type = "success";
			this.alert.text = this.strings.alert_good;
			this.alert.mailto = null;
			
			this.loading = false;
			this.curtain = true;
		},
		gettext: function (block) {
			return block[this.lang];
		}
	},
	computed: {
		button_disabled: function () {
			return !(this.agreed && this.form_valid && !(this.curtain || this.loading));
		}
	},
	watch: {
		lang: function () {
			this.strings = i18n[this.lang];
			this.$refs.form.resetValidation();
		}
	},
	mounted: function () {
		this.token = window.location.pathname.slice(1);
		
		// *vomiting emoji* this is officially how race is to be prevented
		this.$nextTick(function () {
			// let the server decode our token
			let inst = this;
			
			this.api_call_get_object(`/yn/${this.token}`, function (resp) {
				inst.serial = resp.serial;
				inst.curtain = false;
			});
		});
	}
};
</script>

<style>
	.yn-serial {
		font-weight: bold;
	}
	
	input::-webkit-outer-spin-button, input::-webkit-inner-spin-button {
		-webkit-appearance: none;
		margin: 0;
	}
	
	input[type=number] {
		-moz-appearance: textfield;
	}
</style>
